.yao-afix-footer {
    position: relative;
    transition: all 0.5s 0s ease;
  }

  @supports (position: sticky) {
    .yao-afix-footer {
      position: -webkit-sticky;
      position: sticky;
      z-index: 1102;
      bottom: 0;
    }
  }