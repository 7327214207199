.yao-afix-footer {
    position: relative;
    transition: all 0.5s 0s ease;
  }

  @supports (position: sticky) {
    .yao-afix-footer {
      position: -webkit-sticky;
      position: sticky;
      z-index: 1102;
      bottom: 0;
    }
  }

  .yao-input-override > * > input {
    padding: 0 !important;
  }
  .yao-input-override > * > select {
    padding: 0 !important;
  }
  