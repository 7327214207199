.contact-input-override > * > input {
  padding: 0 !important;
}
.contact-dialog-content {
  padding: 20px 24px !important;
}
.contact-dialog-action {
  padding: 8px !important;
}

.pac-container {
  z-index: 2000 !important;
}

.accordion-content {
  transition: all 0.3s ease-out;
}
