.yao-input-override > * > input {
  padding: 0 !important;
}

.default-button-style {
  height: 2.5rem;
}

.default-field-style {
  padding: 0;
  height: 2.5rem;
}
