.select-list span {
  background-color: #fff0f3;
}

.select-list svg {
  margin-right: 0.375rem !important;
}

.priority-list fieldset {
  border: 0 !important;
}

.priority-list .MuiBox-root {
  display: none;
}

.priority-list .MuiOutlinedInput-input {
  padding-left: 0.6rem !important;
  padding-right: 1.4rem !important;
}

.correspondence-date button {
  padding-left: 0;
}

.call_note-icon.MuiSvgIcon-root {
  font-size: 30px !important;
}

.call_note-icon svg path {
  stroke: #E49060 !important;
}

.tab_note.MuiSvgIcon-root {
  font-size: 27px !important;
  position: relative;
  top: 0.25rem;
}

.task-heading button {
  display: flex;
  justify-content: flex-start;
  background: transparent !important;
}

.task-heading button,
.action-list .MuiSelect-select,
.action-list.MuiOutlinedInput-root,
.time-textfield .MuiOutlinedInput-root,
.time-textfield input {
  padding-left: 0 !important;
}

.estimate-time .MuiOutlinedInput-input {
  padding: 0 !important;
  border: 0 !important;
  padding-right: 0.2rem !important;
  text-align: right;
}

.todo-button {
  background: transparent !important;
}

.todo-list-item .MuiSelect-select {
  padding: 5px 0 5px 5px !important;
}

.todo-list-item svg {
  position: relative;
  top: 0;
  left: 0;
}

.todo-list svg {
  height: 15px;
  fill: #a4afb9;
}

.comment-list-icon {
  background-color: #000 !important;
  color: #e4607b;
}

.comments-icons {
  padding-top: 55px !important;
  justify-content: flex-end;
  display: flex;
}

.comments-icons:hover {
  cursor: pointer !important;
}

.multi-tag .MuiOutlinedInput-root .MuiButtonBase-root svg {
  height: 16px;
  width: 16px;
}

.multi-tag .css-1ug6j6a-MuiButtonBase-root-MuiChip-root {
  background: transparent;
  border: 1px solid #A4AFB9;
  border-radius: 0.1875rem;
  height: 1.25rem;
  margin: 0 3px 0 0 !important;
}

.multi-tag .MuiButtonBase-root span {
  color: #212B36;
  font-size: 0.6875rem;
}

.multi-tag .css-1ug6j6a-MuiButtonBase-root-MuiChip-root svg {
  height: 1rem;
  width: 1rem;
}

.multi-tag .css-1ug6j6a-MuiButtonBase-root-MuiChip-root {
  background: transparent;
  border: 1px solid #A4AFB9;
  border-radius: 0.1875rem;
  height: 1.25rem;
  margin: 0 3px 0 0 !important;
}

.multi-tag .MuiButtonBase-root span {
  color: #212B36;
  font-size: 0.6875rem;
}

.multi-tag .css-1ug6j6a-MuiButtonBase-root-MuiChip-root svg {
  height: 1rem;
  width: 1rem;
}

.cs_corresponding-modal .MuiPaper-root {
  min-width: 50rem;
}
